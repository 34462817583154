export const TRANSLATE_MAPPING_KEY = {
  ESTIMATED_ARRIVAL: 'estimatedArrival',
  DELIVERED_AT: 'deliveredAt',
  KFC_WEB_APP: 'kfcWebAndApp',
  RDP_NO_DELIVERY: 'rdpNoDelivery',
  DELIVERY_HRS: 'deliveryHours',
  OPEN_NOW: 'openNow',
  TRADING_HRS: 'tradingHours',
  ORDER_RECEIVED: 'orderReceived',
  ENJOY_MEAL: 'enjoyMeal',
  STORE_CLOSING_SOON: 'storeCloseSoonMain',
  STORE_CLOSE_SOON_CONTENT: 'storeCloseSoonContent',
  COMPLETE_ORDER_BUTTON: 'completeOrderButtonText',
  FIND_NEARBY_STORE: 'findNearByStore',
  ASK_FOR_OTP: 'askForOTP',
  ORDER_READY: 'orderGettingReady',
  FOUND_DRIVER: 'foundDriver',
  CANCEL_ORDER: 'cancelOrder',
  CANCEL_ORDER_REFUND: 'cancelOrderRefund',
  BUSINESS_DAYS: 'businessDays',
  DELIVERY_ADDRESS_LINE_LABEL: 'deliveryAddressLineLabel',
  SWITCH_TO_TAKEAWAY_TEXT: 'switchToTakeaway',
  RETRY_BTN_TEXT: 'retry',
  VIEW_MAP_BTN_TEXT: 'viewMapErrorButton',
  ADDRESS_NOT_FOUND_ERROR: 'deliveryUnavailableErrorTextWithCTA',
  DELIVERY_UNAVAILABLE_ERROR: 'noStoresWithDeliveryHeader',
  DELIVERY_IS_CURRENTLY_UNAVAILABLE: 'deliveryIsCurrentlyUnavailable',
  PICKUP_UNAVAILABLE_ERROR: 'noStoresWithPickupHeader',
  GOOGLE_API_ERROR: 'googleApiError',
  NO_KFC_NEAR_YOU_ERROR: 'nokfcnearyou',
  BROWSE_MENU_TEXT: 'browseMenu',
  ORDER_MODE: 'orderMode',
  OK: 'OK',
  LAST_ORDER_CHECKIN_AT: 'lastOrderCheckInAt',
  IS_CLOSING_SOON: 'isClosingSoon',
  closingSoon: 'closingSoon',
  CHECK_IN_AHEADTIME: 'checkInAheadTime',
  ORDER_NO_LONGER_CHECKEDIN: 'orderNoLongerCheckedIn',
  HAS_NOW_CLOSED: 'hasNowClosed',
  ORDER_TYPE: 'orderType',
  YOUR_LOCATION: 'yourLocation',
  SPECIAL_NOTE: 'specialNoteDetail',
  YOUR_KFC_RESTAURANT: 'YourKFCLocation',
  SCHEDULE_FOR_YOUR_ORDER_TEXT: 'scheduleForYourOrder',
  ORDER_TYPE_CHANGE: 'change',
  START_ORDER: 'startOrder',
  SCHEDULE_ORDER: 'scheduleOrder',
  BROWSE_MENU: 'browseMenu',
  ORDER_NOW: 'orderNow',
  AMENITIES: 'amenities',
  DELIVERY_PLACEHOLDER: 'enterYourDeliveryAddress',
  PICKUP_PLACEHOLDER: 'addressBoxPlaceHolderForSearchStore',
  SELECT_KFC_TITLE: 'selectKFC',
  DELIVERY_ADDRESS_TITLE: 'deliveryAddress',
  STORE_CLOSE_ERROR_PICKUP: 'storeUnavailableForPickup',
  STORE_CLOSE_ERROR_DELIVERY: 'storeUnavailableForDelivery',
  LTO_URL: 'limited-time-only',
  MAP_TEXT: 'map',
  LIST_TEXT: 'list',
  SAVED_ADDRESS: 'savedAddress',
  SAVED_ADDRESSES: 'savedAddresses',
  NEAR_YOU: 'nearYou',
  DELIVER_TO_YOU: 'deliverToYou',
  DOWNLOAD_APP: 'downloadApp',
  CONFIRM_BTN_TEXT: 'confirm',
  LOCATION_WARNING_MESSAGE: 'addressSuggestionHeader',
  USE_THIS_ADDRESS_TEXT: 'useAddress',
  CLOSED_BTN_TEXT: 'closed',
  CLOSED: 'closed',
  CLOSED_NEXT: 'closedNext',
  OPENAT: 'openAt',
  TECHNICAL_ERROR: 'technicalError',
  OPEN24HR: 'open24Hr',
  OPEN_UNTIL: 'openUntil',
  OPENS_AT: 'open',
  OPEN_TODAY: 'openToday',
  OPEN_TODAY_WITHOUT_AT: 'openTodayWithoutAt',
  OPEN_TOMORROW: 'openTomorrow',
  OPEN_TOMORROW_WITHOUT_AT: 'openTomorrowWithoutAt',
  PAYMENT_FAILED_MESSAGE: 'paymentdeclined',
  PAYMENT_PRE_VALIDATION_FAILED_MESSAGE: 'paymentPreValidationFailed',
  CART_CHECKOUT: 'cartCheckout',
  DELIVERY_INFO: 'deliveryInfo',
  TAKEAWAY_INFO: 'takeawayInfo',
  DINEIN_INFO: 'dineInInfo',
  DELIVERY_OPTIONS: 'deliveryOptions',
  TAKEAWAY_OPTIONS: 'takeawayOptions',
  DINEIN_OPTIONS: 'dineInOptions',
  CONTACT_INFO: 'contactInfo',
  PAYMENT: 'payment',
  CART_ITEM: 'cartItem',
  CART_ITEMS: 'cartItems',
  CART_SUB_TOTAL: 'cartSubTotal',
  CART_GST: 'cartGst',
  CART_INCLU_TAX: 'cartIncluTax',
  RESTAURANT_HANDLING: 'restaurantHandling',
  ADD_HOPE_HEADER: 'addHopeHeader',
  ADD_HOPE_TEXT: 'addHopeText',
  LARGE_CARRY_BAG_CONTENT: 'largeCarryBagContent',
  LARGE_CARRY_BAG: 'largeCarryBag',
  ADD_HOPE: 'addhope',
  DISCOUNT_TEXT: 'DiscountText',
  CONTINUE_PAYMENT: 'continuePayment',
  TODAY: 'today',
  ASAP: 'asap',
  TOMORROW: 'tomorrow',
  CHANGE: 'change',
  ADDRESS2_PLACE_HOLDER: 'address2Placeholder',
  LEAVE_AT_MY_DOOR_DESC: 'leaveatMyDoorDesc',
  LEAVE_AT_MY_DOOR: 'leaveatMyDoor',
  WALK_IN_STORE: 'walkInStore',
  DINE_IN_STORE: 'DineInStore',
  HAND_IT_TO_ME: 'handItToMe',
  DELIVERY_INSTRUCTIONS: 'deliveryInstructions',
  KFC_TO_YOUR_CAR: 'KfcToYourCar',
  KFC_TO_YOUR_CAR_DESC: 'KfcToYourCarDesc',
  VEHICLE_INFORMATION: 'vehicleInformation',
  ADD_PAYMENT_METHOD_HEADER: 'addPaymentMethodHeader',
  SUBSCRIBE_EMAIL: 'subscribeEmail',
  FULL_NAME: 'fullName',
  FIRST_NAME_LABEL: 'firstNameLabel',
  FIRST_NAME_PLACE_HOLDER: 'firstNamePlaceholder',
  LAST_NAME_LABEL: 'lastNameLabel',
  LAST_NAME_PLACE_HOLDER: 'lastNamePlaceHolder',
  CALL: 'call',
  PHONE: 'phone',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  EMAIL_ADDRESS: 'emailAddress',
  SUBSCRIPTION: 'subscription',
  VEHICAL_INFORMATION: 'vehicalInformation',
  DELIVERY_INSTRUCTION: 'deliveryInstruction',
  MANDATORY_NOTE: 'mandatorynote',
  PLEASE_ENTER_YOUR_VEHICAL_INFORMATION: 'pleaseEnterYourVehicalInformation',
  INVALID_VEHICAL_INFORMATION: 'invalidVehicalInformation',
  SIGN_IN: 'signIn',
  EMPTY_PUBLICATION_DETAILS_ERR_MSG: 'emptyPublicationDetailsErrMsg',
  EMPTY_FULL_NAME_ERR_MSG: 'emptyFullNameErrMsg',
  INVALID_FULL_NAME_ERR_MSG: 'invalidFullNameErrMsg',
  EMPTY_PHONE_NUMBER_ERR_MSG: 'emptyPhoneNumberErrMsg',
  INVALID_PHONE_NUMBER_ERR_MSG: 'invalidPhoneNumberErrMsg',
  EMPTY_EMAIL_ERR_MSG: 'emptyEmailErrMsg',
  INVALID_EMAIL_ERR_MSG: 'invalidEmailErrMsg',
  CONTINUE_AS_A_GUEST: 'continueAsAGuest',
  SIGN_IN_TO_YOUR_ACCOUNT: 'signInToYourAccount',
  ORDER_SUMMARY: 'orderSummary',
  TOTAL: 'total',
  EMPTY_FIRST_NAME_ERR_MSG: 'emptyFirstNameErrMsg',
  INVALID_FIRST_NAME_ERR_MSG: 'invalidFirstNameErrMsg',
  EMPTY_LAST_NAME_ERR_MSG: 'emptyLastNameErrMsg',
  INVALID_LAST_NAME_ERR_MSG: 'invalidLastNameErrMsg',
  PAYMENT_OPTIONS: 'paymentOptions',
  VIEW_OUR: 'viewour',
  TERMS_OF_USE: 'termsofuse',
  AND: 'and',
  PRIVACY: 'privacy',
  ADD_CONTACT_TO_ENABLE_PAYMENT: 'addContactToEnablePayment',
  INFO_MAKE_SURE_RIGHT_NUMBER_EMAIL: 'infoMakeSureRightNumberEmail',
  CONTINUE: 'continue',
  EDIT: 'edit',
  SERVES_TEXT: 'SERVES_TEXT',
  CART_SUMMARY_NOTE: 'cartSummaryNote',
  GST_INCLUDED: 'GSTincluded',
  CUSTOMISE: 'customise',
  CUSTOM: 'custom',
  QUANTITY_TEXT: 'quantity',
  SAVE_CHANGES: 'saveChanges',
  NOT_AVAILABLE: 'notavailable',
  SEE_CUSTOMISED_ITEMS: 'seeCustomisedItems',
  SEE_INCLUDED_ITEMS: 'seeIncludedItems',
  CART_EDIT_NOTIFICATION: 'cartEditLineItemConfirmationtextMessage',
  TOTAL_ORDER_OVER_LIMIT_ERROR: 'totalOrderErrorOverlimit',
  TOTAL_ORDER_BETWEEN_LIMIT_ERROR: 'totalOrderErrorBetweenlimit',
  REMOVE: 'cartRemove',
  BACK_TO_MENU: 'backToMenu',
  PLACE_ORDER: 'placeOrder',
  CLICK_PLACE_ORDER_CONFIRM_AGREE: 'clickPlaceOrderConfirmAgree',
  LOCATION_BLOCKED_ERROR: 'locationBlockedError',
  SELECT_STORE_TEXT: 'SelectStore',
  KFC_URL: 'kfcUrl',
  LEGALINFOTEXT: 'legalInfoText',
  LEGALINFOTEXT1: 'legalInfoText1',
  LEGALINFOTEXT2: 'legalInfoText2',
  TRACK_ORDER: 'trackOrder',
  NO: 'No',
  FOR: 'for',
  NO_FAVOURITE_STORE: 'noFavStore',
  REMOVED_MSG: 'removedFromFavouriteStores',
  NO_ADDRESS: 'noAddress',
  DELETE_CONFIRM_MSG: 'deleteConfirmMsg',
  CURRENT_ORDER: 'currentOrder',
  PROCESSING: 'processing',
  PROCESSING_YOUR: 'processingYour',
  ORDER_READY_IN_JUST: 'orderReadyInJust',
  A_SECOND_TEXT: 'aSecondText',
  HANG_TIGHT: 'hangTight',
  CONFIRMING: 'confirmingPayment',
  YOUR_PAYMENT: 'yourPayment',
  CONFIRM_YOUR_ORDER: 'confirmYourOrder',
  READY_IN_JUST_A_SECOND: 'readyInJustASecond',
  SAVE_ORDER_THANKYOU: 'saveOrderThankYou',
  WE_SAVED: 'weSaved',
  GIVE_A_NAME: 'giveAName',
  SAVED_ORDER_SCHEDULE_ORDER: 'savedOrderScheduleOrder',
  ORDER_DETAILS: 'orderDetails',
  SCHEDULED: 'scheduled',
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  REORDER_FAVORITES: 'reorderFavourites',
  CREATE_KFC_ACCOUNT: 'createKfcAccount',
  YOU_RE_ALL_SET: 'YOU’RE ALL SET',
  WILL_BE_DELIVERED_AROUND: ' will be delivered around',
  WILL_BE_READY_FOR_PICKUP: 'willBeReadyForPickup',
  READY_FOR_PICKUP: 'readyForPickup',
  GIVE_US_YOUR_NAME_OR_ORDER_NUMBER: 'GIVE US YOUR NAME OR ORDER NUMBER',
  WHEN_YOU_RE_HERE: 'WHEN YOU’RE HERE.',
  SAVE_YOUR_ORDERS_AND_CHECKOUT_FASTER: 'SAVE YOUR ORDERS AND CHECKOUT FASTER',
  SAVED_THIS_ORDER_IN_MY_FAVORITE_MENU: 'Saved this order in My Favorite Menu.',
  CREATE_AN_ACCOUNT_TO_SAVE_YOUR_ORDERS_AND: 'CREATE AN ACCOUNT TO SAVE YOUR ORDERS AND',
  CHECKOUT_FASTER: 'CHECKOUT FASTER',
  SAVE_THIS_ORDER: 'Save This Order',
  VIEW_MORE_DETAILS: 'View More Details',
  ADD_HOPE_CUSTOMER_CARE: 'addHopeCustomerCare',
  BACK_TO_CART: 'backToCart',
  BACK_TO_ADD_HOPE: 'backToAddHope',
  THAT_NOT_RIGHT: 'thatNotRight',
  UNSUCCESSFUL_PAYMENT: 'unsuccessfulPayment',
  FEEDBACK_EMAIL: 'FeedbackEmail',
  GO_BACK_TO_CART: 'GoBackToCart',
  ORDER_ACCEPTED: 'orderAccepted',
  SIGN_AND_SAVE_ORDER: 'signAndSaveOrder',
  READY_FOR_DINEIN: 'readyForDineIn',
  DINEIN_TEXT: 'dineInText',
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  DINE_IN: 'dineIn',
  CATERING: 'catering',
  CHRISTMAS_ORDERING: 'christmasOrdering',
  DELIVERY_FEE: 'deliveryFee',
  YOUR_ORDER: 'yourOrder',
  ORDER_HASH: 'orderHash',
  COMPLETED: 'completed',
  DELIVER_TO: 'deliveryTo',
  ORDER_FROM: 'orderFrom',
  GET_DIRECTION: 'getDirection',
  SMS_MARKETING: 'smsMarketing',
  EMAIL_MARKETING: 'emailMarketing',
  SUB_TOTAL: 'subTotal',
  DISCOUNT: 'discount',
  ORDER: 'order',
  OCCASION_NAME_VALIDATION: 'OccasionNameValidation',
  AT: 'At',
  PAYMENT_HEADER: 'paymentHeader',
  TOTAL_GST_INCLUDED: 'gstIncluded',
  PREPARED_BY: 'preparedBy',
  INSTRUCTIONS: 'instructions',
  DELIVERY_ADDRESS: 'deliveryAddress',
  HOURS: 'hours',
  CANCEL: 'cancel',
  CANCELED: 'cancelled',
  SAVE: 'save',
  FINDING_DRIVER: 'findingDriver',
  ORDER_ON_THE_WAY: 'orderOnTheWay',
  IS_ON_THE_WAY: 'isOnTheWay',
  DRIVER_ARRIVING: 'driverArriving',
  RECKON_TEXT: 'reckOnText',
  SAVE_TO_FAVOURITE_ORDER: 'saveToFavouriteOrder',
  SAVE_FAV_ORDER: 'savetoFav',
  TEXT: 'text',
  WE_RE_READY_TO_MAKE_YOUR_ORDER: 'wereReadyToMakeYourOrder',
  WE_VE_FOUND_DELIVERY_DRIVER: 'weveFoundDeliveryDriver',
  WE_RE_MAKING_YOUR_ORDER_FRESH: 'wereMakingYourOrderFresh',
  DELIVERY_DRIVER: 'deliveryDriver',
  ITS_BUSY_AND_ITS_TAKING_A_WHILE_TO_FIND_A_DRIVER: 'itsBusyAndItsTakingAWhileToFindADriver',
  HAS_ACCEPTED_TO_DELIVER_YOUR_ORDER: 'hasAcceptedToDeliverYourOrder',
  IS_ON_HIS_WAY_TO_THE_RESTAURANT: 'IsOnHisWayToTheRestaurant',
  HAS_BEEN_DELAYED: 'hasBeenDelayed',
  IS_ALMOST_THERE: 'isAlmostThere',
  CONTACT: 'contact',
  NO_ORDER_MSG: 'noOrderFor12',
  REFUND_MSG: 'refundMessage',
  HAVE_A_QUESTION: 'haveAQuestion',
  CONNECT_WITH_SPECIALIST: 'connectWithASpecialist',
  YOUR_PAST_ORDER: 'yourPastOrder',
  LOOKING_FOR_OFFER: 'lookingForSpecificOrder',
  ORDER_HISTORY_TAG: 'orderHistory',
  CURRENT_ORDERS_TEXT: 'currentOrders',
  COMPLETED_ORDERS_TEXT: 'completedOrders',
  GET_HELP: 'getHelp',
  LOAD_MORE: 'loadMore',
  VIEWED: 'Viewed',
  OF: 'of',
  ORDER_LOOKUP: 'orderLookup',
  VIEW_MENU: 'viewMenu',
  ADDRESS: 'address',
  STOREADDRESS: 'addressPlaceHolder',
  SIGN_OUT: 'signOut',
  GREETING_GOOD: 'greetingGood',
  KFC_LOVER: 'kfcLover',
  TODAY_AT: 'todayAt',
  EDIT_ITEM_TEXT: 'editItemText',
  REFUND_INIT_TEXT: 'refundInitiated',
  SCHEDULE: 'scheduled',
  REORDER_TEXT: 'reorderBtn',
  ADD_TO_OCCASION: 'addToOccasion',
  VIEW_IN_FAVOURITES: 'viewInFavourites',
  VIEW_RECEIPT: 'viewReceipt',
  SUBTOTAL: 'subTotal',
  GST: 'gst',
  OCCASSION_ADD_HEADING: 'occasionGiveName',
  OCCASSION_EDIT_HEADING: 'occasionEditName',
  ORDER_PLACEHOLDER: 'nameThisOrder',
  NAME_EXIST: 'nameExistMsg',
  ORDER_ADDED_TO_FAVOURITE: 'addedToYourFavourites',
  FAVOURITES: 'favouritesHeader',
  RESTAURANT_HANDLING_CHARGE: 'cartIncluTax',
  INSTRUCTIONS_TITLE: 'instructions',
  HOURS_TEXT: 'hours',
  ITEM_NOT_UNAVAILABLE: 'itemNotAvailable',
  ITEM_CURRENLTY_UNAVAILABLE: 'itemCurrentlyUnavailable',
  STORE_CLOSED_ERROR: 'storeClosedError',
  GENERIC_STORE_ERROR: 'genericStoreError',
  FAVORITE_ORDERS: 'favouriteOrders',
  EDIT_FAVOURITE_HEADING: 'editFavourite',
  ORDER_RECEIPT: 'orderReceipt',
  FAVORITE_ORDER_NAME_EDITED: 'favouriteNameUpdate',
  REMOVED_FROM_FAVORDERS: 'removedFromFav',
  REMOVE_FROM_FAVMODAL_MSG: 'removeFromFavOrdersMsg',
  NO_ORDER_HISTORY_MSG: 'noOrderHistoryMsg',
  ADDRESS_PLACEHOLDER: 'addressPlaceHolder',
  YES_DELETE: 'yesDelete',
  MYDELIVERYADDRESSES: 'MyDeliveryAddresses',
  DELIVERY_ADD_DELETE: 'deliveryAddressDeleted',
  MY_OCCASSION_UNAVAILABLE_TITLE: `myOccassionUnavailable`,
  REMOVE_FAVOURITES: 'removeFavourites',
  PROCESSING_ORDER: 'processingOrder',
  PROCESSING_ADD_HOPE_PAYMENT: 'processingAddHopePayment',
  REVIEW_ORDER: 'reviewOrder',
  ORDER_AGAIN: 'orderAgain',
  ITEM: 'items',
  ORDER_TYPE_DIFFERENT_ERROR: 'orderTypeDifferentError',
  LOCATION_DIFFERENT_ERROR: 'locationDifferentError',
  MY_ACCOUNT: 'myAccount',
  QUICK_ORDER: 'orderItAgain',
  ORDER_IT_AGAIN: 'orderItAgain',
  WELCOME_TO: 'welcomeTo',
  KFC_TEXT: 'kfcTxt',
  SEE_FULL_ORDER_HISTORY: 'seeFullOrderHistory',
  WELCOME_BACK: 'welcomeBackText',
  THAT_AINT_RIGHT: 'thatNotRight',
  FAILED_MSG: 'unsuccessfulPayment',
  POST_ORDER_ERROR_MSG_AU: 'postOrderErrorMsgAU',
  BACK_TO_CHECKOUT: 'backToCheckOut',
  CURRENT_ORDERS: 'currentOrders',
  ORDER_GETTING_READY: 'orderGettingReady',
  YOUR_ORDER_IS_SCHEDULED: 'yourOrderIsScheduled',
  PICKUP_DETAILS: 'pickupDetails',
  TRACK_DELIVERY: 'trackDelivery',
  SCHEDULED_TIME: 'scheduledTime',
  GUEST_USER_SINGIN_TEXT: 'signIn',
  ORDERS_NOW: 'ordersNow',
  WE_ARE_WORKING_ON: 'weAreWorking',
  HAS_BEEN_CONFIRMED: 'hasBeenConfirmed',
  HAS_SCHEDULED: 'hasScheduled',
  SPECIAL_CHAR_ERROR: 'nameIsInvalid',
  OCCASION_NAME_LENGTH_ERROR: 'favouriteNameLengthError',
  EMPTY_NAME_ERROR: 'emptyMsg',
  ADD_ON_MSG: 'addOnMsg',
  ITEM_ADDED_TO_CART: 'itemAddedToOrder',
  MORE_INFO: 'moreInfo',
  APT_SUITE_FLOOR: 'aptSuiteFloor',
  SAVE_DELIVERY_ADDRESS: 'savedeliveryaddress',
  SAVE_ADDRESS_BOOK: 'savetoaddressbook',
  SEE_PROMOS_FOR_YOU: 'seePromosForYou',
  PROMOS_AND_REWARDS: 'promosRewards',
  FOR_MEMBERS_ONLY: 'forMembersOnly',
  KFC_SIGN_IN_EXCLUSIVE_PROMOS: 'kfcSignInExclusivePromos',
  FREE_DEVIVERY_WITH_WICKED_WINGS: 'freeDeliverywithWickedWings',
  CHOOSE_ANY_PRODUCT: 'chooseAnyProduct',
  PICKUP_METHODS: 'pickupMethods',
  READY_TO_COLLECT: 'readyToCollect',
  EDIT_FAVORITE_NAME: 'editFavourite',
  CANCEL_BTN_TXT: 'cancelButtonText',
  SUBMIT: 'Submit',
  MORNING: 'morning',
  NOON: 'noon',
  EVENING: 'evening',
  DATE: 'date',
  TIME: 'time',
  CATERING_SCHEDULE: 'cateringSchedule',
  CATERING_WARNING: 'cateringWarning',
  ADDRESS_SAVED: 'addressSaved',
  ADDRESS_UPDATED: 'addressUpdated',
  SCHEDULE_ORDER_BTN_TEXT: 'ScheduleOrder',
  ADDRESS_NICK_NAME: 'addressNickNameOptional',
  YOUR_CART_EMPTY: 'yourCartEmpty',
  SET_LOCATION: 'setLocation',
  PROMOS_REWARDS: 'promosRewards',
  MEMBERS_ONLY: 'forMembersOnly',
  VIEW_ALL_PROMOS: 'viewAllPromos',
  CHECK_BACK_AGAIN: 'checkBackAgain',
  THATS_ALL_FOR_NOW: 'thatsAllForNow',
  SIGNIN_TEXT: 'kfcSignInExclusivePromos',
  ITEM_IN_CART: 'itemInCart',
  ONE_PROMO_APPLIED: 'onePromoApplied',
  DELIVERY_PROMOTION: 'deliveryPromotion',
  PICKUP_PROMOTION: 'pickupPromotion',
  VIEW_CART: 'viewCart',
  CHANGE_DELIVERY: 'changeDelivery',
  CHANGE_PICKUP: 'changePickup',
  ADD_TO_CART: 'pdpAddToCart',
  VIEW_DETAILS: 'viewdetails',
  ADD_TO_ORDER: 'addToOrder',
  VIEW_PRODUCT: 'viewProduct',
  VALID_TO: 'validTo',
  CUSTOMISE_YOUR: 'cutomizeYour',
  YES: 'Yes',
  YES_REMOVE: 'yes_Remove',
  REVEIW_CONDITION: 'review_Condition',
  GOT_IT_BUTTON: 'gotIt',
  PROMO_TEXT: 'promo',
  MAX_ORDER_PROMO: 'maxOrderPromo',
  PROMO_REMOVE_CONFIRMATION: 'promosRemoveConfirmation',
  PROMO_SUCCESS_TOAST_MESSAGE: 'promosSuceesToastMessage',
  PROMO_REMOVE_TOAST_MESSAGE: 'promosRemoveToastMessage',
  PROMO_UNMET_MESSAGE: 'promosUnmetMessage',
  PROMO_UNMET_SUMMARY_MESSAGE: 'promosUnmetSummaryMessage',
  SIGN_IN_SIGN_UP: 'signInSignUp',
  SIGN_UP: 'signUp',
  SPACE_FIRST_NAME_ERR_MSG: 'pleaseEnterAValidFirstName',
  SPACE_LAST_NAME_ERR_MSG: 'pleaseEnterAValidLastName',
  IN_PROGRESS: 'inprogress',
  PROCESSING_REFUND: 'processingRefund',
  VIEWING: 'viewing',
  ADD: 'add',
  RECOMMENDED_HEADER_TITLE: 'upSellTitle',
  RECOMMENDED_HEADER_TITLE_CATERING: 'moreCrowdPleaser',
  START_NEW_ORDER: 'startNewOrder',
  MENU_NAVALERT_TITLE: 'cateringToMenuNavTitle',
  MENU_NAVALERT_SUBTITLE: 'cateringToMenuNavContentMsg',
  CATERING_NAVALERT_TITLE: 'menuToCateringNavTitle',
  CATERING_NAVALERT_SUBTITLE: 'menuToCateringNavContentMsg',
  CATERING_TEXT: 'cateringText',
  ACCOUNT_SETTING: 'accoundSetting',
  DELIVERY_TITLE: 'deliveryTitle',
  PICKUP_TITLE: 'pickupTitle',
  CALL_KFC_STORE: 'callKFCstore',
  NO_PAYMENT_OPTION_AVAILABLE: 'noPaymentOptionAvailable',
  HERE_TO_HELP: 'hereToHelp',
  CHANGED_YOUR_MIND: 'changedYourMind',
  CANCEL_ORDER_BUTTON_TEXT: 'cancelOrderButtonText',
  CALL_SUPPORT_ORDER_BUTTON_TEXT: 'callSupportButtonText',
  SELECT_PICK_UP_METHOD: 'selectPickupMethod',
  PICKUP_INSIDE_RESTAURANT: 'pickupInsideRestaurant',
  PICKUP_FROM_WINDOW: 'pickupFromWindow',
  PICKUP_FROM_CURB_SIDE: 'parkInTheStore',
  IN_STORE: 'inStore',
  DRIVE_THRU: 'driveThru',
  CURB_SIDE: 'curbside',
  PICK_UP_COLLECTION_METHOD: 'pickUpCollectionMethod',
  DRIVE_THRU_PICK_UP: 'driveThruPickUp',
  CURB_SIDE_PICK_UP: 'curbsidePickUp',
  IN_STORE_PICK_UP: 'inStorePickUp',
  CAR_NUMBER_PLATE: 'carNumberPlate',
  CAR_NUMBER_PLATE_LABEL: 'carNumberPlateLabel',
  CONFIRM: 'confirm',
  VEHICAL_DETAILS: 'vehicleDetailsForCurbside',
  UNAVAILABLE: 'unavailable',
  CANCEL_PICKUP_ORDER: 'cancelPickupOrder',
  FULL_PAYMENT_REFUND: 'fullPaymentRefund',
  KEEP_ORDER: 'keepOrder',
  CHECIN_AT_DRIVE_THRU: 'checInAtDriveThru',
  CHECIN_AT_IN_STORE: 'checkInAtInStore',
  CHECIN_AT_CURB_SIDE: 'checkInWhenParked',
  READY_TO_PREPARE_MEAL: 'readyToPrepareMeal',
  I_AM_AT_DRIVE_THRU_QUEUE: 'iAmAtDriveThruQueue',
  I_AM_INSIDE_RESTAURANT: 'iAmInsideRestaurant',
  I_AM_AT_CURB_SIDE: 'imNowParkedCurbside',
  CHANGE_COLLECTION_METHOD: 'changeCollectionMethod',
  LOREM_IPSUM_DOLOR_SIT_AMET_CONJUNCIVUS: 'loremIpsumDolorSitAmetConjuncivus',
  emergencyStoreCloseMain: 'emergencyStoreCloseMain',
  COLLECTED_AT: 'collectedAt',
  EFFECTIVE_DATE: 'effectiveDate',
  DELIVERED_AROUND: 'deliveredAround',
  MESSAGE_TAG: 'message',
  MESSAGES_TAG: 'messages',
  INDULDENT_ADD_ONS_HEADING: 'induldentAddOnsHeading',
  NOT_SIGNED_IN: 'notSignedIn',
  CATERING_PROMO_ERROR: 'cateringPromoError',
  ORDERS: 'orders',
  NOW: 'now',
  yourSearch: 'yourSearch',
  returnedNoResults: 'returnedNoResults',
  quantityDecreseText: 'quantityDecreseText',
  quantityIncreaseText: 'quantityIncreaseText',
  quantityUpdateText: 'quantityUpdateText',
  BROWSE_MENU_HEADER: 'browseMenuHeader',
  ALLERGEN_PRESENT_TEXT: 'present',
  ALLERGEN_PRESENT_DETAILS: 'allergenPresentDetails',
  ALLERGEN_TRACE_TEXT: 'trace',
  ALLERGEN_TRACE_DETAILS: 'allergenTraceDetails',
  NOT_APPLICABLE: 'na',
  NUTRITION: 'nutrition',
  ALLERGEN: 'allergens',
  NON_ALA_CARTE_ALERT_TEXT: 'nonAlaCarteAlertText',
  NON_ALA_CARTE_ALERT_LINK: 'nonAlaCarteAlertLink',
  DISCLAIMER: 'disclaimer',
  PER_SERVE: 'per_serve',
  PER_GRAM: 'per_gram',
  NUTRITION_ALLERGEN_INFORMATION: 'nutrition_allergen',
  INVALID_CUSTOMER_INFO_ERROR: 'invalidCustomerInfoError',
  PICKUP_CANCALLED_ORDER: 'pickupCancalledOrder',
  PRODUCT_UNAVAILABLE: 'productUnavailable',
  CURRENTLY_UNAVAILABLE: 'currentlyUnavailable',
  SELECT_TEXT: 'selectText',
  ADD_TO_YOUR_ORDER: 'addToYourOrder',
  CART_CATERING_ALERT_TEXT: 'cartCateringAlertText',
  CART_CATERING_ALERT_STARTTEXT: 'cartCateringAlertStartText',
  CART_CATERING_ALERT_ENDTEXT: 'cartCateringAlertEndText',
  CART_CATERING_CONTENT_TEXT: 'cartCateringContentText',
  CATERING_CART_ERROR_MSG: 'cateringCartErrorMsg',
  ROUND_THE_CLOCK: 'roundTheClock',
  BIRTHDAY: 'birthday',
  BREAKFAST: 'breakfast',
  CONTACTLESS: 'contactless',
  DRIVETHRU: 'driveThru',
  FOODCOURT: 'foodCourt',
  FREE_WIFI: 'freeWifi',
  WIFI: 'wifi',
  KIOSKS: 'kiosks',
  PARKING: 'parking',
  PLAYGROUND: 'playground',
  REFILL: 'refill',
  TERRACES: 'terraces',
  ACCESSIBILTY: 'phcWheelChairAccess',
  RESTROOM: 'restroom',
  RDP_ACCESSIBILITY: 'rdpaccessibility',
  RDP_RESTROOM: 'rdpRestroom',
  SOMEONE_ELSE_COLLECTING_ORDER: 'someoneElseCollectingOrder',
  INFORM_TO_CONTACT_NOMINATED_PERSON_ORDER: 'informToContactNominatedPersonOrder',
  ENTER_CONTACT_DETAILS: 'enterContactDetails',
  THANK_YOU: 'thankYou',
  CATERING_CONFIRMATION_EMAIL: 'cateringConfirmationEmail',
  YOUR_CATERING: 'yourCatering',
  EXPLORE_CATERING: 'exploreCatering',
  ADDON_ADD_TO_CART: 'addToCart',
  INDULDENT_ADD_ON_MSG: 'induldentAddOnsAddedMessage',
  CONTACT_US: 'contactUs',
  HELP_DROPDOWN: 'helpDropdown',
  ENQUIRY_DROPDOWN: 'enquiryDropdown',
  SPECIFIC_DROPDOWN: 'specificDropdown',
  POSTCODE_ERROR: 'postcodeError',
  SORTVISIT_ERROR: 'sortVisitError',
  SORTVISIT_TEXT: 'sortvisit',
  ORDERNUMBER_ERROR: 'orderNumberError',
  ENQUIRYDETAILS_ERROR: 'enquiryDetailsError',
  VISITEDDATE_ERROR: 'visitedDateError',
  VISITEDTIME_ERROR: 'visitedTimeError',
  GET_TOUCH: 'getTouch',
  PHONE_HELP_TEXT: 'phoneHelpText',
  EMAIL_HELP_TEXT: 'emailHelpText',
  FEEDBACK_SUCCESS: 'feedbackSuccess',
  FEEDBACK_SUCCESS_MESSAGE: 'feedbackSuccessMessage',
  FEEDBACK_SUCCESS_OK: 'feedbackSuccessOk',
  TAKE_ME_HOME: 'takeMeHome',
  THANK_COMMENT_MSG: 'thankYouCommentMessage',
  IS_SCHEDULED_TO_BE_PICKED_UP_AT: 'is scheduled to be picked up at',
  KJ: 'kJ_text',
  PHONE_STATIC_TEXT: 'phoneStaticText',
  EMAIL_STATIC_TEXT: 'emailStaticText',
  THANKS_STATIC_TEXT: 'thanksStaticText',
  ENQUIRY_STATIC_TEXT: 'enquiryStaticText',
  GLAD_STATIC_TEXT: 'gladStaticText',
  ERROR_MESSAGE_IN_ACTIVE: 'errorMessageInactive',
  WHICH_MENU: 'willYouBeOrdering',
  CATERTING_MENU: 'cateringMenu',
  STORE_MENU: 'storeMenu',
  AFTER_NOON: 'afterNoon',
  REFUNDED: 'refunded',
  STORE_CLOSED_TEXT: 'storeCloseText',
  STORE_TEXT: 'store',
  CATERING_STORE_CLOSED_MSG: 'cateringClosedStoreMsg',
  SAVED_DELIVERY_ADDRESS: 'savedDeliveryAddress',
  SEARCH_NUTRITION_ALLERGEN: 'searchNutritionAllergen',
  CANCELDELIVERYORDERREFUND: 'cancelDeliveryOrderRefund',
  I_AM_HERE_TEXT: 'iAmHereText',
  I_AM_ELSE_WHERE_TEXT: 'iAmElseWhereText',
  CONFIRM_YOU_ARE_HERE_TEXT: 'confirmYouAreHereText',
  READY_TO_START_COOKING: 'readyToStartCooking',
  WE_ARE_PREPARING_MEAL: 'weArePreparingMeal',
  CALL_OUT_WHEN_MEAL_IS_READY: 'callOutWhenMealIsReady',
  SET_YOUR_LOCATION: 'setYourLocation',
  CHECKOUT_UNAVAILABLE_ITEM: 'checkoutUnavailable',
  CHECKOUT_UNAVAILABLE_AT_STORE: 'checkoutUnavailableAtStore',
  ENTER_YOUR_Name: 'enterYour',
  INVALID_TEXT: 'invalid',
  PAYMENT_FAILED_TITLE: 'paymentFailedTitle',
  PAYMENT_FAILED_SUBTITLE: 'paymentFailedSubtitle',
  PAYMENT_FAILED_HEADER: 'paymentFailedHeader',
  RETUEN_TO_CART: 'returnToCart',
  RETUEN_TO_CHECKOUT: 'returnToCheckout',
  ASAP_MENU_AVAILABLE: 'asapMenuAvailable',
  SCHEDULE_MENU_NOT_AVAILABLE: 'scheduleMenuNotAvailable',
  TODAY_SCHEDULE_MENU_AVAILABLE: 'todayScheduleMenuAvailable',
  SCHEDULE_MENU_AVAILABLE: 'scheduleMenuAvailable',
  UNAVAILABLE_ITEMS: 'unavailableItem',
  REMOVE_ALL_UNAVAILABLE_TEXT: 'removeAllunavailableText',
  TIP_YOUR_DRIVER: 'tipYourDriver',
  TIP_YOUR_DRIVER_DESC: 'tipYourDriverDesc',
  ADD_DRIVER_TIP: 'addDriverTip',
  TIP_ADDED_TOAST_MESSAGE: 'tipAddedToastMessage',
  UPDATE_DRIVER_TIP: 'updateDriverTip',
  TIPPING_INVALID_MSG: 'tippingInvalidMessage',
  TIPPING_EXCEEDED_MSG: 'tippingExceededMessage',
  DRIVER_TIP: 'driverTip',
  BOTTLE_DEPOSIT: 'bottleDeposit',
  PICK_UP_TIME: 'pickUpTime',
  DELIVERY_TIME: 'deliveryTime',
  SCHEDULE_ORDER_ERROR: 'scheduledTimePassed',
  SCHEDULE_ORDER_CAPACITY_ERROR: 'pleaseSelectNewTime',
  SCHEDULE_ORDER_TIME_SELECT: 'timeSlotNoLongerAvailable',
  NO_TIME_SLOTS_SELECT_ANOTHER: 'noTimeSlotsSelectAnother',
  NO_TIME_SLOTS_AVAILABLE: 'noTimeSlotsAvailable',
  MY_PAYMENT_METHODS: 'myPaymentMethods',
  SAVED_CARDS: 'savedCards',
  NO_SAVED_CARDS: 'noSavedCards',
  DELETE_CARD: 'deleteCard',
  CARD_DELETE_CONFIRMATION: 'cardDeleteConfirmation',
  CARD_DELETED: 'cardDeleted',
  DELETE: 'delete',
  EXP: 'expiry',
  SCHEDULE_ORDER_CA: 'scheduledTimePassed',
  SPECIAL_HOURS: 'specialHours',
  CLOSED_HOURS: 'closedHours',
  CLOSED_TIME: 'closedTime',
  STORE_CLOSED_NEXT_TIME_APPLIED_PICKUP_MSG: 'storeClosedTimeAppliedPickup',
  STORE_CLOSED_NEXT_TIME_APPLIED_DELIVERY_MSG: 'storeClosedTimeAppliedDelivery',
  SAVED_CARD_TITLE: 'savedCardPaymentTitle',
  OTHER_PAYMENT_TITILE: 'otherPaymentTitle',
  PAYMENT_SECTION_TITLE: 'paymentSectionTitile',
  ORDER_TIME_PASSED_TITLE: 'orderTimePassedTitle',
  ORDER_TIME_PASSED_MSG: 'orderTimePassedMsg',
  SCHEDULE_TIME_NOT_AVAILABLE: 'scheduledTimeNotAvailable',
  SCHEDULE_TIME_NOT_AVAILABLE_MSG: 'hitKitchenCapacity',
  SCHEDULE_TIME_NOT_AVAILABLE_BUTTON: 'selectNewTime',
  RESCHEDULE_ORDER_TIME_BUTTON: 'rescheduleOrderTimeButton',
  ORDER_ASAP: 'orderAsap',
  SPECIAL_CLOSE: 'specialClose',
  ORDER_UPDATE_TEXT: 'orderUpdateText',
  SCHEDULE_ORDER_BTN: 'scheduleOrder',
  BUILD: 'build',
  SPECIAL_EVENT_TITLE: 'scheduleOrder',
  SCHEDULED_TEXT: 'SCHEDULED',
  PAY_ON_ARRIVAL_CHECKBOX: 'payOnArrivalCheckboxLabel',
  PAY_ON_ARRIVAL_CHECKBOX_DESCRIPTION: 'payOnArrivalCheckboxDesc',
  PAY_ON_ARRIVAL_TOAST_MESSAGE: 'selectPaymentMethodToastMsg',
  I_WILL_PAY_NOW: 'iWillPayNow',
  I_WILL_PAY_WHEN_PICKUP: 'iWillPayWhenIPickUp',
  PROMO_ADD_TO_CART: 'promoAddToCart',
  STORE_NOT_SERVING_CURRENTLY: 'storesNotServingCurrently',
  PAYMENT_BY: 'paymentSectionTitle',
  SERVES: 'Serves',
  TO_CONTINUE: 'toContinue',
  SENT_TO_POS_STATUS: 'sentToPosStatus',
  TODAY_ASAP: 'Today ASAP',
  ADD_GIFT_CARD_OR_VOUCHER: 'addGiftCardOrVoucher',
  ENTER_WICODE: 'enterWiCode',
  CANCEL_WICODE: 'cancelWicode',
  VOUCHER_CODE: 'voucherCode',
  REMOVE_GIFT_CARD: 'removeGiftCard',
  REMOVE_VOUCHER: 'removeVoucher',
  GIFT_CARD_CONTENT: 'giftCardContent',
  GIFT_CARD_ERROR_FIRST_MSG: 'giftCardErrFirstMsg',
  GIFT_CARD_ERROR_SECOND_MSG: 'giftCardErrSecondMsg',
  VOUCHER_ERROR_SECOND_MSG: 'voucherErrSecondMsg',
  VOUCHER_CONTENT: 'voucherContent',
  GIFT_CARD_TOAST: 'giftCardToast',
  VOUCHER_TOAST: 'voucherToast',
  ENTER_COUPON_PLACEHOLDER: 'enterCouponPlaceHolder',
  COUPON_REDEEM_ERROR: 'couponErrMsgBSDE0022',
  COUPON_EXPIRE_ERROR: 'couponExpireError',
  COUPON_INCORRECT_ERROR: 'couponErrMsgBSBR003',
  MAX_VOUCHER_LIMIT_EXCEEDED: 'maxVoucherLimitExceeded',
  NOT_ABLE_TO_VALIDATE_CARD: 'notAbleToValidateCard',
  REVIEW_CARD_AND_BILLING_ADDRESS: 'reviewCardAndBillingAddress',
  RETURN_TO_PAYMENT_OPTIONS: 'returnToPayemntOptions',
  VALIDATING: 'cardValidatingTitle',
  NEW_CARD: 'newCardText',
  CARD_VALIDATION_MESSAGE: 'cardValidationDesc',
  CREDIT_CARD_DETAILS: 'creditCardDetails',
  BILLING_ADDRESS: 'billingAddress',
  CARD_NUMBER: 'cardNumberText',
  ENTER_VALID_CARD_NUMBER: 'enterValidCardNumber',
  INVALID_CARD_NUMBER: 'invalidCardNumber',
  CVV: 'cvv',
  ENTER_CVV_NUMBER: 'enterCvvNumber',
  INVALID_CVV_NUMBER: 'invalidCvvNumber',
  EXPIRY_DATE: 'expiryDate',
  EXPIRY_DATE_YY: 'mmddText',
  ENTER_VALID_CARD_EXPIRY_DATE: 'enterValidCardExpiryDate',
  INVALID_EXPIRY_DATE_FORMAT: 'invalidExpiryDateFormat',
  NAME_ON_CARD: 'nameOnCardText',
  ENTER_YOUR_NAME_ON_CARD: 'enterYourNameOnCard',
  INVALID_NAME: 'invalidName',
  USE_DELIVERY_ADDRESS_FOR_BILLING_ADDRESS: 'useDeliveryAddressForBilling',
  SAVE_CARD_FOR_FASTER_CHECKOUT: 'saveCardForFastCheckout',
  CARD_NICK_NAME: 'cardNickname',
  ENTER_CARD_NICK_NAME: 'enterYourNickname',
  ENTER_YOUR_ADDRESS: 'enterYourAddress',
  INVALID_ADDRESS: 'invalidAddress',
  POSTAL_CODE: 'postalCode',
  ENTER_YOUR_POSTAL_CODE: 'enterYourPostalCode',
  INVALID_POSTAL_CODE: 'invalidPostalCode',
  NO_IMAGE: 'noImage',
  TO: 'to',
  FROM: 'from',
  STILL_HAVE_A_QUESTION: 'haveQuestion',
  POST_ORDER_COUPON_FAILURE: 'postOrderCouponFailure',
  ORDER_WITHOUT_PAYMENT: 'orderWithoutPayment',
  MEAL_FULLY_COVERED_DELIVERY_FEE_REMAINING: 'mealFullyCoveredDeliveryFeeRemaining',
  GIFT_CARD_MEAL_FULLY_COVERED: 'giftCardMealFullyCovered',
  VOUCHER_MEAL_FULLY_COVERED: 'voucherMealFullyCovered',
  GIFT_CARD_ABOVE_SUBTOTAL: 'giftCardAboveSubtotal',
  MAX_SUBTOTAL_VALUE_COVERED: 'maxSubTotalValueCovered',
  VOUCHER_ABOVE_SUBTOTAL: 'voucherAboveSubtotal',
  MANDATORY_TEXT: 'mandatory-text',
  USE_DELIVARY_ADDRESS: 'useDeliveryAddressForBilling',
  SELECT_PICKUP_TIME: 'selectPickupTime',
  SELECT_DELIVERY_TIME: 'selectDeliveryTime',
  MOBILE_ERROR_MESSAGE: 'mobileErrorMessage',
  GIFT_CARD_PURCHASE_ORDER_SUCCESS_MESSAGE_PART_ONE: 'giftCardPurchaseSuccess1',
  GIFT_CARD_PURCHASE_ORDER_SUCCESS_MESSAGE_PART_TWO: 'giftCardPurchaseSuccess2',
  GIFT_CARD_PURCHASE_MSG: 'giftCardPurchaseMsg',
  RETURN_TO_GIFT_CARD: 'returnToGiftCards',
  GIFT_CARD: 'giftCard',
  ADD_NEW_CARD: 'addNewCardButtonText',
  MAX_SAVED_CARD_LIMIT: 'maxSavedCardLimitWarningMsg',
  CLEAR_SEARCH: 'clearSearch',
  GIFT_CARD_VOUCHER_CONTENT: 'giftCardVoucherContent',
  REMOVE_GIFT_CARD_VOUCHER: 'removeGiftCardVoucher',
  PLEASE_FILL_OUT_THIS_FIELD: 'pleaseFillOutThisFiled',
  DELIVERY_OPTIONS_TOOL_TIP: 'deliveryOptionsToolTip',
  PHONE_NUMBER_PLACEHOLDER_NOT_REQUIRED: 'phoneNumberPlaceHolderNotRequired',
  MIN_AMOUNT_INVALID_MSG: 'minAmountErrorMessageForGiftCard',
  MAX_AMOUNT_INAVLAID_MSG: 'maxAmountErrorMessageForGiftCard',
  TIP_EXCEED_SUBTOTAL: 'tipExceedsSubtotal',
  RESULTS: 'results',
  SOMETHING_WENT_WRONG: 'somethingWentWrongErr',
  SOMEONE_SPILL_GRAVY: 'someoneSpillGravy',
  HOME: 'home',
  THERE_NO_FRIED_TEXT: 'thereNoFriedText',
  CHICKEN_PAGE_TEXT: 'chickenOnThisPage',
  WICODE_AUTO_RELEASED: 'wicodeAutoReleasedMsg',
  DECREASE_QUANTITY_BY: 'decreaseQuantityBy',
  INCREASE_QUANTITY_BY: 'increaseQuantityBy',
  OTHER: 'other',
  NOT_NOW: 'notNow',
  TIP_AMOUNT: 'tipAmount',
  GIFT_CARD_PAYMENT_NOT_SUCCESSFUL_HEADLINE_PART_ONE: 'paymentUnsuccessfulPartOne',
  GIFT_CARD_PAYMENT_NOT_SUCCESSFUL_HEADLINE_PART_TWO: 'paymentUnsuccessfulPartTwo',
  GIFT_CARD_PAYMENT_NOT_SUCCESSFUL_DESCRIPTION: 'reviewGiftCard',
  CVV_CREDITCARD: 'cvvCreditCard',
  CVV_CREDITCARD_LABEL: 'cvvCreditCardLabel',
  CARD_NICK_NAME_VALIDATION: 'cardnicknameValidation',
  CVV_CREDIT_CARD_3_DIGIT_ERROR: 'threeDigitCvvError',
  CVV_CREDIT_CARD_4_DIGIT_ERROR: 'fourDigitCvvError',
  TIME_NOT_AVAILABLE: 'timeNotAvailable',
  CART_UPDATE_CLEAR_WICODE_FIRST_TEXT: 'cartUpdateClearWicodeFirstText',
  CART_UPDATE_CLEAR_WICODE_SECOND_TEXT: 'cartUpdateClearWicodeSecondText',
  PLEASE_MODIFY: 'pleaseModify',
  COOKIES_SETTINGS: 'cookiesSettings',
  PREFERENCE_TEXT: 'preferenceText',
  INVALID_CARD_DETAILS: 'invalidCardDetailsErrorMessage',
  ZERO_MESSAGE_COUNT_TEXT: 'zeroMessageCountText',
  SELECT_YOUR_PAYMENT: 'selectPaymentMethod',
  CANCEL_DELIVERY_ORDER: 'cancelDeliveryOrder',
  NO_ADDRESS_FOUND: 'noAddressFound',
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  ITEM_NOT_AVAILABLE_AT_STORE: 'itemNotAvailableAtStore',
  PROMOTIONS_TERMS_CONDITIONS: 'PromotionCompetitiontc',
};
